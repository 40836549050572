<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Static pages
        </h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button
            class="mb-5 add-button"
            @click="addPage()"
          >
            Add
          </b-button>
      </b-col>
    </b-row>

    <vuetable
      ref="pagestable"
      :api-mode="false"
      :fields="fields"
      :data="pages"
    >
    <template slot="active" scope="props">
      <b-form-checkbox v-model="pages[props.rowIndex].active" name="active" class="content-switch" disabled></b-form-checkbox>
    </template>
    <template slot="actions" scope="props">
        <font-awesome-icon
          icon="edit"
          class="action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Edit page"
          @click="editPage(props.rowIndex)"
          ref="btnEdit"
        ></font-awesome-icon>
        <font-awesome-icon
          icon="trash"
          class="ml-2 action-icon"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove page"
          @click="deletePage(props.rowIndex)"
        ></font-awesome-icon>
      </template>
    </vuetable>
  </b-overlay>
</template>
<script>
import Vuetable from "vuetable-2";

  export default {
    data() {
      return {
        fields: [
          {name: "active", sortField: 'Active'}, 
          {name: `title.${this.$root.$i18n.locale}`, title: 'Title', sortField: 'Title'}, 
          {name: "link", sortField: 'Link'}, 
          {name: "actions", sortField: 'Actions'}
        ],
        isLoading: false
      }
    },
    components: {
      Vuetable
    },
    async created() {
      await this.doFetchAll()
      console.log(this.locale)
    },
    methods: {
      async doFetchAll() {
        this.isLoading = true;
        await this.$store.dispatch("Pages/fetchAll");
        this.isLoading = false;
      },
      addPage() {
        this.$router.push({path: 'page-detail'})
      },
      editPage(index) {
        this.$router.push({path: 'page-detail', query: {index: index}})
      },
      async deletePage(index) {
        this.isLoading = true;
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Are you sure you want to remove page?`, 
          {
            title: "Confirm delete",
            okVariant: "danger",
            centered: true,
          }
        );
        if (isConfirmed) {
          this.pages.splice(index, 1)
          await this.$store.dispatch("Pages/update", this.pages);
        }
        this.isLoading = false;
      },
    },
    computed: {
      locale() {
        return this.$root.$i18n.locale;
      },
      pages() {
        return this.$store.state.Pages.pages;
      }
    }
  }
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>